import axios from "axios"
import api from "../api/BaseApi"
import {
  ON_START_LOADING,
  ON_FETCH_EXIST_ORDERS_SUCCESS,
  ON_FETCH_EXIST_ORDERS_FAIL,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  SET_ORDER_TO_UPDATE,
  ON_UPDATE_ORDER_SUCCESS,
  ON_UPDATE_ORDER_FAIL,
  ON_GET_ORDER_STATUSES_SUCCESS,
  ON_CLOSE_MESSAGE,
  ON_GET_ORDER_STATUSES_FAIL,
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_GET_ORDER_DETAILS_SUCCESS,
  CLEAR_ORDER_UPDATE,
  ON_UPDATE_ORDER_STATUS_SUCCESS,
  ON_UPDATE_ORDER_STATUS_FAIL,
  ON_CREATE_RETURN_ORDER_SUCCESS,
  ON_CREATE_RETURN_ORDER_FAIL,
  ON_LOCKER_TO_LOCKER_SUCCESS,
  ON_LOCKER_TO_LOCKER_FAIL,
  CLEAR_LOCKER_TO_LOCKER,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS,
  ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
  ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
  CLEAR_GET_EXTERNAL_NUMBER,
  ON_CREATE_ORDER_FROM_FILE_LOADING,
  ON_CREATE_ORDER_FROM_FILE_SUCCESS,
  ON_CREATE_ORDER_FROM_FILE_FAIL,
  CLEAR_CREATE_ORDER_FROM_FILE,
  ON_CREATE_L2L_ORDER_REQUEST,
  ON_CREATE_L2L_ORDER_SUCCESS,
  ON_CREATE_L2L_ORDER_FAIL,
  ON_UPDATE_ORDER_STATION_SUCCESS,
  ON_UPDATE_ORDER_STATION_FAIL,
  CLEAR_UPDATE_ORDER_STATION,
  ON_TRANSFER_ORDER_SUCCESS,
  ON_TRANSFER_ORDER_FAIL,
  CLEAR_TRANSFER_ORDER,
} from "../actions/types"
import { axiosGetRetry } from "../utils/ReqsRetry"

export const onStartLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOADING,
    })
  }
}

export const clearOrderUpdate = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER_UPDATE,
    })
  }
}

export const onFetchExistOrders = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.fetchOpenOrders, options)
      .then((res) => {
        dispatch({
          type: ON_FETCH_EXIST_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          dispatch({
            type: ON_FETCH_EXIST_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              //errText: err.response.status
              //errTitle: 'err_title_text',     //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
            },
          })

          return
        }

        dispatch({
          type: ON_FETCH_EXIST_ORDERS_FAIL,
          payload: {
            //TODO
            //errTitle: err.response.data.message,
            //errText: err.response.status
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
          },
        })
      })
  }
}

export const onFetchL2LExistOrders = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.fetchL2LOpenOrders, options)
      .then((res) => {
        dispatch({
          type: ON_FETCH_EXIST_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          dispatch({
            type: ON_FETCH_EXIST_ORDERS_FAIL,
            payload: {
              errTitle: err.response.data.message,
              errText: "network_error_text",
            },
          })
          return
        }
        dispatch({
          type: ON_FETCH_EXIST_ORDERS_FAIL,
          payload: {
            errTitle: "err_title_text",
            errText: "wrong_login_err_body_text",
          },
        })
      })
  }
}

export const onCreateOrder = (
  stationNumber,
  packageNumber,
  mobilePhone,
  firstName,
  lastName,
  orderStatus,
  bagsQuantity
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    const body = [
      {
        stationNumber,
        packageNumber,
        externalOrderNumber: packageNumber,
        mobilePhone,
        firstName,
        lastName,
        orderStatus,
        bagsQuantity,
      },
    ]

    axios
      .post(api.createOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        let errTitle = "שגיא כללית"
        let errText = "משהו לא הלך תקין, הפעולה לכשלה."
        if (err && err.response && err.response.data) {
          errTitle = err.response.data.message
          errText = err.response.status
        }
        dispatch({
          type: ON_CREATE_ORDER_FAIL,
          payload: {
            errTitle,
            errText,
          },
        })
      })
  }
}

export const onCreateOrderL2L = (order) => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_L2L_ORDER_REQUEST,
    })
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.createOrderL2L, order, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_L2L_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_L2L_ORDER_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const setSelectedOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER_TO_UPDATE,
      payload: order,
    })
  }
}

export const closeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_MESSAGE,
    })
  }
}

export const onUpdateOrder = (order) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const body = [
      {
        mobilePhone: order.mobilePhone,
        stationNumber: order.stationNumber,
        packageNumber: order.packageNumber,
        firstName: order.firstName,
        lastName: order.lastName,
        orderStatus: parseInt(order.orderStatus),
        inLockerExtentionDate: order.inLockerExtentionDate,
      },
    ]
    axios
      .post(api.updateOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const fetchOrderStatuses = () => {
  return (dispatch) => {
    axios
      .get(api.getOrderStatuses)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_STATUSES_FAIL,
          payload: err,
        })
      })
  }
}

export const fetchOrderTypes = () => {
  return (dispatch) => {
    axiosGetRetry(api.getOrderTypes)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_FAILURE,
          payload: err,
        })
      })
  }
}

export const getOrderDetails = (orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_GET_ORDER_DETAILS_REQUEST })

    axios
      .get(api.getOrderDetails(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_DETAILS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const updateOrderStatus = (orderId, orderStatus) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.updateOrderStatus(orderId, orderStatus), options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const updateOrderStatusAndExtentionDate = (
  orderId,
  orderStatus,
  inLockerExtentionDate
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(
        api.updateOrderStatusAndExtentionDate(
          orderId,
          orderStatus,
          inLockerExtentionDate
        ),
        options
      )
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const onCreateReturnOrder = (
  packageNumber,
  mobilePhone,
  firstName,
  lastName,
  email,
  orderStatus,
  externalOrderNumber2
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const body = [
      {
        packageNumber,
        externalOrderNumber: packageNumber,
        mobilePhone,
        firstName,
        lastName,
        email,
        orderStatus,
        externalOrderNumber2,
      },
    ]

    axios
      .post(api.createReturnOrder, body, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_RETURN_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        let errTitle = "שגיא כללית"
        let errText = "משהו לא הלך תקין, הפעולה נכשלה."
        if (err && err.response && err.response.data) {
          errTitle = err.response.data.message
          errText = err.response.status
        }
        dispatch({
          type: ON_CREATE_RETURN_ORDER_FAIL,
          payload: {
            errTitle,
            errText,
          },
        })
      })
  }
}

export const updateOrderExtentionByAccount = (
  orderId,
  inLockerExtentionDate
) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .post(
        api.updateOrderExtentionByAccount(orderId, inLockerExtentionDate),
        options
      )
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATUS_BY_ACCOUNT_FAIL,
          payload: {
            errTitle: err.response.data?.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const onCreateLockerToLocker = (order) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .post(api.importLockerToLockerOrders, order, options)
      .then((res) => {
        dispatch({
          type: ON_LOCKER_TO_LOCKER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        let errTitle = "שגיא כללית"
        let errText = "משהו לא הלך תקין, הפעולה נכשלה."
        if (err && err.response && err.response.data) {
          errTitle = err.response.data.message
          errText = err.response.status
        }
        dispatch({
          type: ON_LOCKER_TO_LOCKER_FAIL,
          payload: {
            errTitle,
            errText,
          },
        })
      })
  }
}

export const clearLockerToLocker = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LOCKER_TO_LOCKER,
    })
  }
}

export const getExternalNumByPackageAndAccount = (
  packageNumber,
  accountType
) => {
  return (dispatch) => {
    axios
      .get(api.getExternalNumByPackageAndAccount(packageNumber, accountType))
      .then((res) => {
        dispatch({
          type: ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_EXTERNAL_NUMBER_BY_PACKAGE_FAIL,
          payload: err,
        })
      })
  }
}

export const clearGetExternalNumber = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_GET_EXTERNAL_NUMBER,
    })
  }
}

export const onCreateOrdersFromFile = (
  ordersFile,
  accountType,
  orderStatus
) => {
  return (dispatch) => {
    dispatch({ type: ON_CREATE_ORDER_FROM_FILE_LOADING })
    const formData = new FormData()
    formData.append("ordersFile", ordersFile, ordersFile.name)
    axios
      .post(
        api.importOrdersFromCSVByAccount(accountType, orderStatus),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        dispatch({
          type: ON_CREATE_ORDER_FROM_FILE_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_ORDER_FROM_FILE_FAIL,
          payload: err,
        })
      })
  }
}

export const clearCreateOrdersFromFile = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CREATE_ORDER_FROM_FILE,
    })
  }
}

export const onUpdateOrderStation = (orderId, stationNumber, orderType) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.updateOrderStation(orderId, stationNumber, orderType), options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATION_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_ORDER_STATION_FAIL,
          payload: {
            errTitle: err.response.data.message,
            errText: err.response.status,
          },
        })
      })
  }
}

export const clearUpdateOrderStation = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_UPDATE_ORDER_STATION,
    })
  }
}

export const onTransferOrder = (orderId, stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.onTransferOrder(orderId, stationId), options)
      .then((res) => {
        dispatch({
          type: ON_TRANSFER_ORDER_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_TRANSFER_ORDER_FAIL,
        })
      })
  }
}

export const clearTransferOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TRANSFER_ORDER,
    })
  }
}
