import React, { Component, useState, useEffect } from "react"
import { ButtonRectangle } from "../ButtonRectangle"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import NotificationModal from "../NotificationModal"
import "./style.scss"

const OrderInLockerExtendModal = ({
  isOpen,
  isClose,
  submitDate,
  inLockerDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const handleDateChange = (date) => {
    const currentDate = new Date()
    if (date >= currentDate) {
      setSelectedDate(date)
    } else {
      setShowWarning(true)
    }
  }

  useEffect(() => {
    if (inLockerDate && inLockerDate !== "0001-01-01T00:00:00") {
      setSelectedDate(new Date(inLockerDate))
    } else {
      setSelectedDate(null)
    }
  }, [inLockerDate])

  const validateDate = (date) => {
    if (date == null || date == "0001-01-01T00:00:00") {
      setShowWarning(true)
    } else {
      submitDate(selectedDate)
    }
  }

  if (!isOpen) {
    return null
  }
  return (
    <div className="orderDelayModal">
      <div className="modal-content">
        <h2>בחר תאריך להארכת זמן חבילה בלוקר</h2>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          isClearable={false}
          placeholderText="בחר תאריך"
        />

        <div className="btn-holder">
          <ButtonRectangle onClick={(e) => validateDate(selectedDate)}>
            אישור
          </ButtonRectangle>
          <ButtonRectangle onClick={(e) => isClose()}>סגור</ButtonRectangle>
        </div>
      </div>
      <NotificationModal
        type={"warning"}
        title={"תאריך לא תקין"}
        show={showWarning}
        text={"אנא בחר תאריך עתידי."}
        onOkClick={() => setShowWarning(false)}
      />
    </div>
  )
}

export default OrderInLockerExtendModal
